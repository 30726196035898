import React from "react";
import { Route, Routes } from "react-router-dom";

import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import ShouldNotBeLogged from "./middlewares/ShouldNotBeLogged";
import ShouldBeLogged from "./middlewares/ShouldBeLogged";
import Loader from "./components/shared/Loader";
import Login from "./pages/Login";
import HotelLogin from "pages/HotelLogin";
import ShouldBeLoggedHotel from "middlewares/hotel/ShouldBeLogged";

const DashboardRouting = React.lazy(() =>
  import("./modules/dashboard/DashboardRouting")
);

const HotelRouting = React.lazy(() => import("./modules/hotel/HotelRouting"));
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      
    },
  },
});

const AppRouting = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ShouldNotBeLogged>
            <Login />
          </ShouldNotBeLogged>
        }
      />
      <Route
        path="/hotel"
        element={
          <ShouldNotBeLogged>
            <HotelLogin />
          </ShouldNotBeLogged>
        }
      />
   
   

      <Route
        path="Dashboard/*"
        element={
          <ShouldBeLogged>
            <React.Suspense fallback={<Loader />}>
              <QueryClientProvider client={queryClient}>
                <DashboardRouting />
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </React.Suspense>
          </ShouldBeLogged>
        }
      />
      <Route
        path="Hotel/*"
        element={
          <ShouldBeLoggedHotel>
            <React.Suspense fallback={<Loader />}>
              <QueryClientProvider client={queryClient}>
                <HotelRouting />
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </React.Suspense>
          </ShouldBeLoggedHotel>
        }
      />
    </Routes>
  );
};

export default AppRouting;
