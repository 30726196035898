import { _axios } from "interceptor/http-config";

export const _AuthApi = {
  login: (data) => {
    return _axios
      .post("/admin/auth/login", data)
      .then((res) => _AuthApi.storeToken(res?.data?.data?.token));
  },
  hotelLogin: (data) => {
    return _axios
      .post("/hotel/auth/login", data)
      .then((res) => _AuthApi.storeToken2(res.data));
  },
  storeToken: (token) => {
    localStorage.setItem("token", token);
  },
  storeToken2: (token) => {
    localStorage.setItem("token", token.data);
  },

  getToken: () => localStorage.getItem("token"),

  destroyToken: () => {
    localStorage.removeItem("token");
    window.location.reload();
  },


};
