import { Box, Typography, Grid, Button } from "@mui/material";
import { BoxStyled } from "components/styled/BoxStyled";
import { TextFieldStyled } from "components/styled/TextField";
import React from "react";
import {  useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { _AuthApi } from "api/auth";
import { HotelLoginSchema } from "validations/auth";
import LoginBg from "theme/LoginBg";
import { useTranslation } from "react-i18next";
const HotelLogin = () => {
  const { t } = useTranslation("index");

  const navigate = useNavigate();
  const formOptions = { resolver: yupResolver(HotelLoginSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const onSubmit = (input) => {
    _AuthApi
      .hotelLogin(input)
      .then((res) => {
        navigate("/hotel/hotel_account");
      })
      .catch((err) => console.log(err));
  };

  return (
    <LoginBg>
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "background.main",
        }}
      >
        <BoxStyled
          sx={{
            minWidth: "350px",
            width: "30%",
            padding: "40px",
            zIndex: 10000,
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box sx={{ width: "120px" }}></Box>
            <Typography variant="h5" sx={{ color: "origin.main", mt: "10px" }}>
              {t("Hi, Welcome Back")}
            </Typography>
            <Typography
              variant="h6"
              sx={{ color: "lightGray.main", mt: "10px" }}
            >
              {t("Hotel Login")}
            </Typography>
          </Grid>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ width: "100%" }}
          >
            <Box sx={{ width: "100%", mt: "20px" }}>
              <Box sx={{ margin: "0 0 8px 5px" }}>
                <Typography variant="inputTitle">{t("Phone")}</Typography>
              </Box>
              <TextFieldStyled
                sx={{ width: "100%" }}
                placeholder="Enter your email"
                {...register("phone", { validate: true })}
              />
              {errors.phone && (
                <span className="field_level_error" style={{ color: "red" }}>
                  {errors.phone.message}
                </span>
              )}
            </Box>
            <Box sx={{ width: "100%", mt: "20px" }}>
              <Box sx={{ margin: "0 0 8px 5px" }}>
                <Typography variant="inputTitle">{t("Password")}</Typography>
              </Box>
              <TextFieldStyled
                sx={{ width: "100%" }}
                type="password"
                placeholder="Enter your password"
                {...register("password")}
              />
              {errors.password && (
                <span className="field_level_error" style={{ color: "red" }}>
                  {errors.password.message}
                </span>
              )}
            </Box>

            <Button
              sx={{
                display: "block",
                width: 130,
                backgroundColor: "origin.main",
                margin: "30px auto 0 auto",
                "&:hover": {
                  backgroundColor: "#63d2d9",
                  transform: "scale(1.1)",
                },
              }}
              disableOnLoading
              loading={false}
              fullWidth
              type="submit"
              variant="contained"
            >
              {t("Sign In")}
            </Button>
          </Box>
        </BoxStyled>
      </Box>
    </LoginBg>
  );
};

export default HotelLogin;
